<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">검수관리</h2>
      </div>
      <div class="body_section">
        <!-- ref: 엑셀 다운로드에서 사용.. -->
        <InspectListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :defaultStatusId="defaultStatusId"
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultDates="defaultDates"
          :defaultIsMyWork="defaultIsMyWork"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />

        <TableHead>
          <template v-slot:headRight>
            <div class="group_form">
              <button
                class="btn_tertiary btn_medium"
                :disabled="computedCheckedList.length === 0 || computedisSendAlarm"
                @click="onClickSendAlarm"
              >
                <span class="material-icons-outlined send-alarm"> forward_to_inbox </span>검수진행
                요청
                <strong>{{ `(${computedCheckedList.length})` }}</strong>
              </button>
              <span class="bar_view"> </span>

              <SelectBoxPageRange
                :currentMaxRowCount.sync="size"
                @update:currentMaxRowCount="onChangeRowCount"
              />
            </div>
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          :isHorizontalScroll="true"
          :scrollWidth="'1600px'"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 체크박스 -->
            <col style="width: 40px" />
            <!-- 검수상태 -->
            <col style="width: 100px" />
            <!-- 검수차수 -->
            <col style="width: 60px" />
            <!-- 평가상태 -->
            <col style="width: 80px" />
            <!-- 검수번호 -->
            <col style="width: 120px" />
            <!-- 발주번호 -->
            <col style="width: 120px" />
            <!-- 발주명 -->
            <col />
            <!-- 검수요청금액 -->
            <col style="width: 120px" />
            <!-- 프로젝트명 -->
            <col style="width: 140px" />
            <!-- 파트너사 -->
            <col style="width: 100px" />
            <!-- 검수요청일 -->
            <col style="width: 80px" />
            <!-- 검수완료일 -->
            <col style="width: 80px" />
            <!-- 검수담당자 -->
            <col style="width: 160px" />
            <!-- 구매담당자 -->
            <col style="width: 160px" />
          </template>
          <template v-slot:tr>
            <th>
              <CheckboxItem ref="checkAll" @update:checkedNames="onClickedAll" />
            </th>

            <th>검수상태</th>
            <th>검수차수</th>
            <th>평가상태</th>
            <th>검수번호</th>
            <th>발주번호</th>
            <th>발주명</th>
            <th>검수금액</th>
            <th>프로젝트명</th>
            <th>파트너사</th>
            <th>검수요청일</th>
            <th>검수완료일</th>
            <th>검수담당자</th>
            <th>구매담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <InspectListLine
              :rowData="slotProps.rowData"
              :checkedNames.sync="checkedNames"
              :checkedItem.sync="checkedItem"
            />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="14" class="td_empty">검수내역이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
    <template v-slot:popup>
      <AlertPopup
        v-if="isPopAlert"
        alertText="기간을 설정하지 않으셨습니다.<br>모든 데이터를 다운로드 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelAlert"
        @onClickPrimary="onClickConfirmAlert"
      />
      <ExcelDonwloadPopup
        v-if="isPopExcel"
        :excelDataList="excelHeaderList"
        :checkedNames.sync="checkedNamesExcel"
        @onClickClose="onClickCloseExcel"
        @onClickDownload="onClickDownloadExcel"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import { mapState } from "vuex";
import PageWithLayout from "@/components/layout/PageWithLayout";
import ListPage from "@/components/shared/ListPage";
import InspectListFilterBar from "@/components/admin/inspect/list/InspectListFilterBar";
import InspectListLine from "@/components/admin/inspect/list/InspectListLine";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { dateToString, stringToDate } from "@/utils/dateUtils";
import excelKey from "@/constants/excelKey";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { downloadFile } from "@/utils/fileUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";

import { ALERT_ACTION } from "@/store/modules/alert/action";
import { INSPECT_LIST_ACTION } from "@/store/modules/inspect/action";

export default {
  name: "InspectList",
  components: {
    PageWithLayout,
    InspectListFilterBar,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    CheckboxItem,
    InspectListLine,
    AlertPopup,
    ExcelDonwloadPopup,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: `${this.$apiPath.ST_INSPECT}`,
      defaultStatusId: "INITIAL",
      defaultKeywordSelectedId: "title",
      defaultIsMyWork: false,

      //excelKey: excelKey.ORDER,
      checkedNames: [],
      checkedItem: [],
    };
  },
  computed: {
    ...mapState({
      statusList: (state) => state.inspect.statusList,
      keywordList: (state) => state.inspect.keywordList,
    }),

    getCanNewWriteProcess() {
      return LocalStorageManager.shared.getCanNewWriteProcess();
    },
    computedInspectStaus() {
      let ret = true;
      if (this.$route.query.stateCode === "INITIAL") {
        ret = false;
      }
      if (this.$route.query.stateCode === "INSPECT_REQUEST") {
        ret = false;
      }

      return ret;
    },
    computedCheckedList() {
      //여기서 전달데이터 가공
      const ret = this.checkedNames.map((item) => {
        return item.inspectNum;
      });
      return ret;
    },
    computedItemList() {
      //여기서 전달데이터 가공
      const ret = this.checkedNames.map((item) => {
        return item;
      });
      return ret;
    },

    computedisSendAlarm() {
      let ret = false;
      const firstItem = this.computedItemList[0];

      if (this.computedItemList.length === 1) {
        ret =
          this.computedItemList[0].stateCode === "INITIAL" ||
          this.computedItemList[0].stateCode === "INSPECT_REQUEST";
      } else {
        const filterArray = this.computedItemList.filter((item) => {
          return firstItem.stateCode === item.stateCode;
        });
        ret = filterArray.length === this.computedItemList.length;
      }
      return !ret;
    },
  },
  watch: {
    checkedNames(newVal, oldVal) {
      if (this.dataList.length === 0 || this.dataList.length !== newVal.length) {
        this.$refs.checkAll.forceUnChecked();
      } else {
        this.$refs.checkAll.forceChecked();
      }
    },
  },
  created() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(INSPECT_LIST_ACTION).then(() => {
      this.getDataInit({ ...params, status: this.defaultStatusId });
    });
  },

  methods: {
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objDates = this.checkQueryDates(query);
      const objStatus = this.checkQueryStatus(query);

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus);
    },
    getFilterbarParams(objData, isForExcel) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }
      if (status) {
        params.status = status;
      }

      return params;
    },

    onClickedAll(checked) {
      if (checked) {
        this.checkedNames = this.dataList;
      } else {
        this.checkedNames = [];
      }
    },
    onClickSendAlarm() {
      const params = {
        text: `총 ${this.computedCheckedList.length}건의 검수요청 알림을 발송 하시겠습니까?`,
        onClickY: () => {
          this.sendAlarm();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async sendAlarm() {
      const obj = {};
      const firstItem = this.checkedNames[0];

      if (firstItem.stateCode === "INITIAL") {
        //검수요청전
        obj.manualType = "INSPECT_INIT";
      } else if (firstItem.stateCode === "INSPECT_REQUEST") {
        //검수요청
        obj.manualType = "INSPECT_BUSINESS_NEED";
      } else {
        obj.manualType = null;
      }

      obj.targetPk = this.computedCheckedList;
      const result = await ApiService.shared.post(`${this.$apiPath.ALRIM}/sendManual`, obj);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.alert(`총 ${this.computedCheckedList.length}건 발송되었습니다.`);
      const params = this.checkUrlQuery();
      this.getDataInit(params);
      this.checkedNames = [];
    },
    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/inspect`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) {
        this.$store.dispatch(PROGRESS_END_ACTION);
        return;
      }
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: "arraybuffer",
      });

      this.$store.dispatch(PROGRESS_END_ACTION);

      downloadFile(result, `검수관리_${this.moment().format("YYYYMMDD")}.xlsx`);
    },
  },
};
</script>
<style lang="scss" scoped>
.send-alarm {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: middle;
}
</style>
