<template>
  <ListRow :class="{ tr_select: isSelected }">
    <!-- 체크 -->
    <td>
      <CheckboxItem :id="rowData" :checkedNames.sync="checkedNamesSync" />
    </td>
    <!-- 검수상태 -->
    <td>{{ computedStatusName }}</td>
    <!-- 검수차수 -->
    <td>{{ rowData.degree }}</td>
    <!-- 평가상태 -->
    <td>
      <div v-if="rowData.evaluateNum > 0">
        <button
          class="link_subject align_center"
          :data-ellip="rowData.evaluateStatus"
          @click="$windowOpen(`${$routerPath.PERFORMANCE_VIEW}/${rowData.evaluateNum}`)"
        >
          {{ rowData.evaluateStatus }}
        </button>
      </div>
      <div v-else>{{ rowData.evaluateStatus }}</div>
    </td>
    <!-- 검수번호 -->
    <td class="td_ellip" :data-ellip="rowData.inspectNum">
      <router-link
        :to="toPath"
        class="link_subject align_center"
        :data-ellip="rowData.inspectNum"
        v-html="rowData.inspectNum"
      />
    </td>
    <!-- 발주번호 -->
    <td class="td_ellip">
      <button
        class="link_subject align_center"
        :data-ellip="rowData.orderCid"
        @click="$windowOpen(`${$routerPath.ORDER_VIEW}/${rowData.orderCid}`)"
      >
        {{ rowData.orderCid }}
      </button>
    </td>
    <!-- 발주명 -->
    <td class="td_ellip align_left" :data-ellip="rowData.title">
      {{ rowData.title }}
    </td>
    <!-- 검수요청금액 -->
    <td class="align_right">
      {{ checkRequestInspectPrice | currency | nullToDash }}
    </td>
    <!-- 프로젝트명 -->
    <td
      class="td_ellip align_left"
      :data-ellip="`[${rowData.projectCode}] ${rowData.projectName}`"
      v-html="rowData.projectName"
    ></td>
    <!-- 파트너사 -->
    <td class="td_ellip">
      <button
        class="link_subject align_center"
        :data-ellip="rowData.companyName"
        @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${rowData.userNum}`)"
      >
        {{ rowData.companyName }}
      </button>
    </td>
    <!-- 검수요청일 -->
    <td>{{ rowData.inspectRequestDate | dateStringFormat }}</td>
    <!-- 검수완료일 -->
    <td>{{ rowData.inspectCompleteDate | dateStringFormat }}</td>
    <!-- 검수담당자 -->
    <td>{{ rowData.checker }} ({{ rowData.checkerId }})</td>
    <!-- 구매담당자 -->
    <td>{{ rowData.buyer }} ({{ rowData.buyerId }})</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import { getDateString } from "@/utils/dateUtils";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "InspectListLine",
  components: {
    ListRow,
    CheckboxItem,
  },
  mixins: [ListLineMixin],
  props: {
    checkedNames: Array,
    checkedItem: Array,
    rowData: Object,
  },
  computed: {
    toPath() {
      const { stateCode, gubun, inspectNum } = this.rowData;

      let path = ``;
      if (stateCode === "INSPECT_COMPLETE" || stateCode === "INSPECT_APPROVE") {
        const routerPath = this.$routerPath.INSPECT_VIEW;
        path = makePathWithQuery(routerPath, `${inspectNum}`, this.$route.query);
      } else {
        const routerPath = this.$routerPath.INSPECT_WRITE;
        path = makePathWithQuery(routerPath, `${inspectNum}`, this.$route.query);
      }

      return path;
    },
    computedStatusName() {
      let ret = "";
      switch (this.rowData.stateCode) {
        case "INITIAL":
          ret = "검수 요청전";
          break;
        case "INSPECT_REQUEST":
          ret = "검수 요청";
          break;
        case "INSPECT_APPROVE":
          ret = "검수 승인";
          break;
        case "INSPECT_REJECT":
          ret = "검수 반려";
          break;
        case "PURCHASE_APPROVE":
          ret = "구매 검수 승인";
          break;
        case "PURCHASE_REJECT":
          ret = "구매 검수 반려";
          break;
        case "INSPECT_COMPLETE":
          ret = "검수 완료";
          break;
        case "INSPECT_DELETE":
          ret = "검수 취소";
          break;
        default:
          break;
      }
      return ret;
    },
    computedGubunDate() {
      const { gubun, contractStartDate, contractEndDate, goodsDeadLine } = this.rowData;
      let ret = ``;
      if (gubun === "C") {
        ret = `${getDateString(contractStartDate)} ~ ${getDateString(contractEndDate)}`;
      } else {
        ret = `${getDateString(goodsDeadLine)}`;
      }
      return ret;
    },
    computedGubunNumber() {
      const { gubun, inspectNum, upperId } = this.rowData;
      let ret = ``;
      if (gubun === "C") {
        ret = `${upperId}(${inspectNum})`;
      } else {
        ret = `${upperId}`;
      }
      return ret;
    },
    isSelected() {
      const ret = this.checkedNames.find(
        (item) => String(item.inspectNum) === String(this.rowData.inspectNum),
      );

      return !!ret;
    },
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
    checkRequestInspectPrice() {
      return this.rowData.requestInspectPrice == "0" ? "" : this.rowData.requestInspectPrice;
    },
  },
};
</script>
